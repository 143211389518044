export const 용쿠_아이콘_이미지 =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/8893b523-b142-4caa-e41f-10444b5d1f00/publicIcon'

export const 쿠키런_킹덤_로고_이미지 =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/0fdcaeff-cf88-4c39-d028-31837bf33800/public'

export const 브릭시티_로고_이미지 =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/78c2d22d-714a-41da-9840-e360c7df6300/public'

export const 쿠키런_오븐브레이크_로고_이미지 =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/f00a88ce-9140-49e4-abef-cacca9f62a00/public'

export const 쿠키런_모험의_탑_로고_이미지 =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/91fa936a-03d4-4cb7-0765-40f8c1890d00/public'
