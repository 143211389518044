import dynamic from 'next/dynamic'

import React from 'react'

import { StatusCodes } from 'http-status-codes'

import Img from '@/components/common/Img'

const FeedbackButton = dynamic(
  () => import('@/components/ckk/FeedbackButton'),
  {
    ssr: false,
  },
)

interface Props {
  statusCode: StatusCodes

  message: string
}

enum StatusType {
  ERROR = 'ERROR',
}

const Status: React.FC<Props> = ({ statusCode, message }) => {
  let status: StatusType

  if (
    statusCode === StatusCodes.NOT_FOUND ||
    statusCode === StatusCodes.INTERNAL_SERVER_ERROR
  ) {
    status = StatusType.ERROR
  }

  return (
    <div
      style={{
        margin: '5rem 0',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {status === StatusType.ERROR && (
        <Img
          alt="sugar gnome"
          className="mb-6 h-16 w-16 object-contain"
          src="https://blog.kakaocdn.net/dn/cPzQjI/btrjddNRhul/u5edwJelXnCu9QUp0ezjok/img.gif"
        />
      )}
      <div
        style={{
          fontSize: '1rem',
          margin: '0rem 1rem 1rem 1rem',
          textAlign: 'center',
        }}>
        {message}
      </div>
      {status === StatusType.ERROR && <FeedbackButton />}
    </div>
  )
}

export default Status
