// eslint-disable-next-line import/no-extraneous-dependencies
import { config, dom } from '@fortawesome/fontawesome-svg-core'
import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-named-as-default
import reset from 'styled-reset'

import { media, Theme } from '@/styles/theme'
/**
 * @link https://fontawesome.com/docs/web/use-with/react/use-with
 */
config.autoAddCss = false

const GlobalStyle = createGlobalStyle<Theme>`
${reset}
${dom.css()}

/** 전역 스타일 */

*,
*::after,
*::before {
  box-sizing: border-box;
}

/* * {
  margin: 0;
  padding: 0; 
} */

/** 요소 스타일링 */

body {
  font-size: 0.8rem;
  word-break: keep-all;
  font-family: "Nanum Gothic", sans-serif;
  white-space: pre-line;
  color: ${({ title }) => title};

  /** Responsive Design */
  ${media.giant} {
    /** Giant View */
  }
  ${media.desktop} {
    /** Desktop View */
  }
  ${media.tablet} {
    /** Tablet View */
    font-size: 0.8rem;
  }
  ${media.phone} {
    /** Phone View */
    font-size: 0.8rem;
  }
}

img {
  object-fit: contain;
}
td {
  vertical-align: middle;
}
strong {
  font-weight: 700;
}
em {
  font-style: italic;
}
.emphasis {
  font-weight: 700;
}
input {
  cursor: text;
  background-color: transparent;
  outline: none;
  border: none;

  &:active {
  }
}
button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
}
a {
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    color: ${({ linkText }) => linkText};
  }
}

a.anchor {
  display: block;
  position: relative;
  visibility: hidden;
}

/** 사이트 특화 스타일 */

.item-image {
  position: relative;
  line-height: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  justify-self: flex-start;
  vertical-align: middle;
}
.ckkNum {
  font-family: cookierun, sans-serif !important;
  position: absolute;
  z-index: 2;
  right: 3px;
  bottom: 3px;
  font-weight: 600;
  color: #FFF7E7 !important;
  text-align: right;
  text-shadow: -1px -1px 1px #1e0900, 1px -1px 1px #1e0900, -1px 1px 1px #1e0900, 1px 1px 1px #1e0900;
  filter: drop-shadow(0px 0px 1px #1e0900);
  line-height: 1 !important;
}
.is-viewed {
  color: rgba(0,0,0,0.3019607843);
}

/** 쿠키런 킹덤 스타일 */

.NORMAL_MODE {
  background: #83C120;
}
.DARK_MODE {
  background: purple;
}
.MASTER_MODE {
  background: linear-gradient(145deg, #E19EE5, #46B5CC, #45BCCD);
}

.BLACK {
  color: #000;
}
.GREEN {
  color: #27ae60;
}
.YELLOW {
  color: #f39c12;
}
.RED {
  color: #c0392b;
}
.BROWN {
  color: rgb(188,155,126);
}
.BLUE {
  color: rgb(97,161,196);
}
.PURPLE {
  color: rgb(176,79,190);
}

.ANCIENT {
  padding: 0 5px;
  border: 1px solid #cfbcdf;
  background: #bf96e0;
  color: white;
  animation: ANCIENTShine 0.7s infinite, glowing 5000ms infinite;
  animation-timing-function: ease-in-out;
}

.LEGENDARY {
  padding: 0 5px;
  border: 1px solid #a2dbd8;
  color: white;
  animation: LEGENDARYShine 0.5s infinite, glowing 5000ms infinite;
}
.DRAGON {
  padding: 0 5px;
  border: 1px solid #b99883;
  background: #264824;
  color: white;
  animation: DRAGONShine 0.5s infinite, glowing 5000ms infinite;
  animation-timing-function: ease-in-out;
}

.SUPER_EPIC {
  padding: 0 5px;
  border: 1px solid #cfbcdf;
  background: #bf96e0;
  color: white;
  animation: SUPER_EPICShine 0.5s infinite, glowing 5000ms infinite;
  animation-timing-function: ease-in-out;
}

.EPIC {
  padding: 0 5px;
  border: 1px solid #d46d85;
  background: #d95f86;
  color: white;
  animation: EPICShine 0.7s  infinite, glowing 5000ms infinite;
  animation-timing-function: ease-in-out;
}

.RARE {
  padding: 0 5px;
  border: 1px solid #7ab7de;
  background: #5f9fc0;
  color: white;
}

.COMMON {
  padding: 0 5px;
  border: 1px solid #b99883;
  background: #764824;
  color: white;
}

.BEAST-header {
  border: 2px solid #08050E;
  background: linear-gradient(135deg, #401c27, #150909, #551925, #ff074d, #4c112a);
}
.ANCIENT-header {
  border: 2px solid #08050E;
  background: linear-gradient(135deg, #2E1C40, #0D0915, #1C1955, #D507FF, #19114C);
}
.LEGENDARY-header {
  border: 2px solid #5E044D;
  background: linear-gradient(135deg, #CC66FF, #2E61FF, #00C2FF, #00EFD9, #7AEB5E, #34CA79);
}
.SUPER_EPIC-header {
  border: 2px solid #CF6443;
  background: linear-gradient(135deg, #F3AE72, #964674, #7B2999, #C15494, #E58579, #F3AE72, #FEE73A);
}
.EPIC-header {
  border: 2px solid #860234;
  background: linear-gradient(135deg, #DE1656, #9B003E, #D83E6C, #F299AC, #F159E0);
}
.RARE-header {
  border: 2px solid #031E64;
  background: linear-gradient(135deg, #0D5BB6, #05247B, #1051A9, #3DBCDD, #1C99D3);
}
.COMMON-header {
  border: 2px solid #402319;
  background: linear-gradient(135deg, #78412F, #5B301D, #774c35, #AE7650, #845831);
}
.SPECIAL-header {
  border: 2px solid #B36718;
  background: linear-gradient(135deg, #ECA712, #DB8C01, #F4BD39, #FFFF97, #FAE749);
}
.GUEST-header {
  border: 2px solid #4347D5;
  background: linear-gradient(135deg, #6f5be3, #3438a8, #BB74F4, #e69ed3, #e69ed3, #DB8BFB, #985dc9)
}
.DRAGON-header {
  border: 2px solid #AE7650;
  background: linear-gradient(135deg, #78412F, #C4A668, #725E1B, #AE7650, #ECA712);
}

.WATER {
  color: #1C99D3;
}
.FIRE {
  color: #BD3134;
}
.LIGHT {
  color: #f7aa05;
}
.ELECTRICITY {
  color: #f5d12a;
}
.POISON {
  color: #985dc9;
}
.EARTH {
  color: #AE7650;
}
.ICE {
  color: #40bcd9
}
.DARKNESS {
  color: #684FE6;
}
.WIND {
  color: #A2FF7A;
}
.DARK {
  color: #EDD4FB;
}

/** react-player 스타일 Override */

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/** Adsense */

.adsense-horizontal {
  width: 100%;
  height: 90px !important;
  max-height: 90px !important;
  ${media.phone}{
    height: 50px !important;
    max-height: 50px !important;
  }
}
.anchor-bottom {
  position: sticky;
  z-index: 1000;
  bottom: 0;
  background: rgba(0,0,0,0.2);
}

/** Quill Editor */

#editor {
  .ql-editor {
    min-height: 200px;
    background: white;
  }
  .ql-video { 
    width: 100%;
    max-width: 500px;
    aspect-ratio: 1.77778 / 1;
  }
  .ql-align-center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  img {
    max-width: 960px;
  }
  p {
    line-height: 1.5;
  }
  .ql-snow .ql-picker.ql-size {
    width: 70px;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    border: 0.5px solid rgba(0,0,0,0.2);
  }
  .ql-toolbar.ql-snow {
    border: 0.5px solid rgba(0,0,0,0.2);
  }
  .ql-container.ql-snow {
    border: 0.5px solid rgba(0,0,0,0.2);
  }
  .ql-snow .ql-tooltip {
    border-radius: 0.3rem;
    border: 0.5px solid rgba(0,0,0,0.2);
  }
  .ql-editor ol, .ql-editor ul {
    padding-left: 0.5rem;
  }
}

.speech-bubble {
  margin-left: 1rem;
  padding: 0.7rem 1.1rem;
  /* width: 100%; */
  height: 100%;
	position: relative;
	background: #ffffff;
	border-radius: .4em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.3;
  color: rgba(0,0,0,0.7);
  font-size: 0.8rem;
  font-family: cookierunRegular, sans-serif;

  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-right-color: #ffffff;
    border-left: 0;
    border-top: 0;
    margin-top: 2.5px;
    margin-left: -7px;
  }
}
.outlined {
  color: white;
  text-shadow:  -1px -1px 0px #1e0900,
                1px -1px 0px #1e0900,
                -1px 1px 0px #1e0900,
                1px 1px 0px #1e0900,
                -1px 0px 0px #1e0900,
                0px -1px 0px #1e0900,
                1px 0px 0px #1e0900,
                0px 1px 0px #1e0900,
                0px 2.3px 0px #1e0900;
}
.ckk {
  font-family: cookierun, sans-serif !important;
}
.ckkBold {
  font-family: cookierun, sans-serif !important;
  font-weight: 700;
}
:where(.css-dev-only-do-not-override-1ck3jst).ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 0.3rem;
}

/**
 * Ant Design 스타일 Override
 */

/** List */

.ant-list-item {
  padding: 0.4rem 0.5rem 0.4rem 0.5rem;
  border-radius: 3px;
  box-shadow: 0px 1px 2px #c4c4c4;
  background: ${({ mainBackground }) => mainBackground};
}
.ant-list-item-meta-avatar {
      margin: 0px;
}

/** Menu */

.ant-menu-item-icon {
  min-width: 18px !important;
  margin: 0px !important;
  display: flex;
  align-items: center;
}

/** Modal */

.ant-modal-confirm-paragraph {
  max-width: unset !important;
}
.ant-modal-confirm-title {
  font-weight: 900 !important;
}
.ant-modal {
  .ant-modal-header {
    padding: 12px 16px;
    border-radius: 7px 7px 0 0;
    border-bottom: 1px solid rgba(0,0,0,0.1) !important;
    margin-bottom: 0px !important;
    .ant-modal-title {
      font-weight: 700;
      font-size: 15px;
    }
  }
  .ant-modal-content {
    border-radius: 7px;
    padding: 0px !important;
    .ant-modal-confirm-content {
      width: 100%;
      margin-block-start: 0px !important;
      margin-inline-start: 0px !important;
      max-width: none !important;
    }
  }
}
.ant-modal-confirm  {
  .ant-modal-header{
    border-radius: 4px 4px 0px 0px;
    .ant-modal-title {
      font-weight: 700;
    }
  }
  .ant-modal-footer {
    border-radius: 0px 0px 4px 4px;
  }
  .ant-modal-content {
    border-radius: 7px;
    .ant-modal-body {
      padding: 0 !important;
      border-radius: 7px;
      .ant-modal-confirm-body {
        text-align: center;
        .ant-modal-confirm-title {
          padding: 0.7rem;
          border-bottom: 0.5px solid rgba(0,0,0,0.1);
        }
        .ant-modal-confirm-content {
          padding: 1rem;
        }
      }
      .ant-modal-confirm-btns {
        width: 100%;
        margin: 0;
        .ant-btn {
          min-height: 2.5rem;
          width: 100%;
          border-radius: 0 0 7px 7px;
        }
      }
    }
  }
}
.ant-modal-confirm-body > .anticon {
  display: none;
}
.ant-modal-confirm .ant-modal-confirm-paragraph {
  row-gap: 0px !important;
}

/** Form */

.ant-form-item-control-input {
  min-height: 0px !important;
}
.ant-form-item-no-colon {
  height: unset !important;
}
.ant-form-item {
  margin: 0 0 8px;
}

/** Grid */

.ant-col {
  min-height: unset;
}

/** Button */

.ant-btn:focus, .ant-btn:hover {
  color: white;
}

/** Table */

td.ant-table-cell.best {
  background: #f6e58d;
  font-weight: 700;
}
td.ant-table-cell.highlight {
  background: #f6e58d;
  font-weight: 700;
}
tbody {
  .stretch-td {
    vertical-align: unset;
    min-height: 100%;
    position: relative;
  }
}

/** Card */

.ant-card {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 1px 3px #c4c4c4;
  .mainImage {
    width: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 20rem;
    ${media.tablet} {
      max-width: unset;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .ant-card-head {
    min-height: unset;
    /* min-height: 0; */
    padding: 0 1rem;
    .ant-card-head-wrapper {
      .ant-card-head-title {
        font-weight: 700;
        padding: 8px 0;
      }
    }
  }
  .ant-card-body {
    padding: 0 !important;
    display: flex;
    ${media.tablet} {
      flex-direction: column;
    }
  }
  .content {
    padding: 1rem;
    ${media.tablet} {
      border-left: none;
    }
  }
}

/** Description */

.ant-descriptions {
  .ant-descriptions-header {
    padding: 10px 15px;
    margin: 0px !important;
    .ant-descriptions-title {
      font-weight: bold;
    }
  }
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  vertical-align: middle;
  text-align: center;
}

/** Notification */

:where(.css-dev-only-do-not-override-ht6oo7).ant-notification .ant-notification-notice-wrapper .ant-notification-notice-message {
  margin: 0;
}

/** Radio */

.ant-radio-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/** Upload */

:where(.css-dev-only-do-not-override-dkbvqv).ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 0rem 0.7rem;
}

/* Hamburger Icon */

#nav-icon {
  width: 20px;
  height: 15px;
  position: relative;
  margin: 25px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: black;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
  top: 6px;
}

#nav-icon span:nth-child(4) {
  top: 12px;
}

#nav-icon.open span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

#nav-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}


/** Animation */

@keyframes loader {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}
@keyframes float {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    -ms-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 1.5s;
}

`

export default GlobalStyle
