import { FirebaseApp, initializeApp } from 'firebase/app'
import { getToken } from 'firebase/messaging'

const FCM_SERVER_KEY =
  'AAAA4MnyutU:APA91bFet7qfqZIu3F5sI_MPE02rOavWMxMOK8n6FWYQUDuYxYddUCbwFCDaHeMPHV5m-524c_t6jwykpXQN1HjiSmDPPTrVnxeP6gRWPcRnAOaeYv1oBdYEKBQyjAxSy-i04wNEOixO'

// checking whether token is available in indexed DB
const tokenInlocalforage = async (): Promise<string> => {
  const { default: localforage } = await import('localforage')
  return localforage.getItem('fcm_token')
}

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: 'AIzaSyA3tkwiYS5FCx809TX2EEZiR4oJaJ1I5nM',
  authDomain: 'sugar-gnome-warehouse.firebaseapp.com',
  projectId: 'sugar-gnome-warehouse',
  storageBucket: 'sugar-gnome-warehouse.appspot.com',
  messagingSenderId: '965460802261',
  appId: '1:965460802261:web:a22ffa92682309482a005c',
  measurementId: 'G-Q83QVE94HB',
}

export const getFirebaseApp = (): FirebaseApp => {
  return initializeApp(firebaseConfig)
}

// eslint-disable-next-line @typescript-eslint/no-shadow
export const getFcmToken = async (messaging): Promise<string> => {
  try {
    const { permission } = Notification
    if (permission === 'default') {
      // requesting notification permission from browser
      const nextPermission = await Notification.requestPermission()

      if (nextPermission === 'granted') {
        // getting token from FCM
        const fcm_token = await getToken(messaging)
        if (fcm_token) {
          const { default: localforage } = await import('localforage')
          // setting FCM token in indexed db using localforage
          localforage.setItem('fcm_token', fcm_token)
          // return the FCM token after saving it
          return fcm_token
        }
      }
    } else if (permission === 'granted') {
      const tokenInLocalForage = await tokenInlocalforage()

      // if FCM token is already there just return the token
      if (tokenInLocalForage !== null) {
        return tokenInLocalForage
      }
    }
  } catch (_) {
    return null
  }
}

export const subscribeTokenToTopic = (token: string, topic: string): void => {
  fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'key=' + FCM_SERVER_KEY,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        throw '주제 구독 실패: ' + response.status + ' - ' + response.text()
      }
      // console.log(`${topic} 주제 구독 성공`)
    })
    .catch((_) => {
      // console.error(error)
    })
}

export const unsubscribeTokenToTopic = async (token: string, topic: string) => {
  fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: 'key=' + FCM_SERVER_KEY,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        throw (
          '주제 구독 취소 실패: ' + response.status + ' - ' + response.text()
        )
      }
      // console.log(`${topic} 주제 구독 취소 성공`)
    })
    .catch((_) => {
      // console.error(error)
    })
}

export const getAllSubscribedTopics = async (
  token: string,
): Promise<string[]> => {
  const response = await fetch(
    'https://iid.googleapis.com/iid/v1/' + token + '?details=true',
    {
      method: 'POST',
      headers: new Headers({
        Authorization: 'key=' + FCM_SERVER_KEY,
      }),
    },
  )

  if (response.status < 200 || response.status >= 400) {
    throw (
      '모든 토픽 가져오기 실패: ' + response.status + ' - ' + response.text()
    )
  }
  const {
    rel: { topics },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = (await response.json()) as any

  return Object.keys(topics)
}
