import { ReactNode } from 'react'

import { Tag } from 'antd'

import { NotificationType } from '@/types/notification'

export const NOTIFICATION_TYPE_MAP: Record<
  NotificationType,
  { title: ReactNode; tag: ReactNode }
> = {
  [NotificationType.ARTICLE_NEW_COMMENT]: {
    title: (
      <>
        내가 <span className="font-bold">작성한 글</span>에{' '}
        <span className="font-bold">새로운 댓글</span>이 달렸습니다.
      </>
    ),
    tag: <Tag color="blue">쿠키런: 킹덤 자유게시판 글</Tag>,
  },
  [NotificationType.DECK_NEW_COMMENT]: {
    title: (
      <>
        내가 <span className="font-bold">작성한 덱</span>에{' '}
        <span className="font-bold">새로운 댓글</span>이 달렸습니다.
      </>
    ),
    tag: <Tag color="orange">쿠키런: 킹덤 덱게시판 글</Tag>,
  },
  [NotificationType.CKT_ARTICLE_NEW_COMMENT]: {
    title: (
      <>
        내가 <span className="font-bold">작성한 글</span>에{' '}
        <span className="font-bold">새로운 댓글</span>이 달렸습니다.
      </>
    ),
    tag: <Tag color="cyan">쿠키런: 모험의 탑 자유게시판 글</Tag>,
  },
  [NotificationType.NEW_COMMENT_REPLY]: {
    title: (
      <>
        내가 <span className="font-bold">작성한 댓글</span>에{' '}
        <span className="font-bold">새로운 댓글</span>이 달렸습니다.
      </>
    ),
    tag: <Tag color="gold">댓글</Tag>,
  },
}
