import { ReactNode, useEffect } from 'react'

import debounce from 'lodash/debounce'
import { create } from 'zustand'

import { DeviceSize } from '@/types/deviceSize'

export const MOBILE_WIDTH_BREAKPOINT = 576
export const PC_WIDTH_BREAKPOINT = 1200

const useDeviceSize = create<{
  deviceSize: DeviceSize
  setDeviceSize: (deviceSize: DeviceSize) => void
}>((set) => ({
  deviceSize: DeviceSize.MOBILE,
  setDeviceSize: (deviceSize) =>
    set({
      deviceSize,
    }),
}))

interface Props {
  children: ReactNode
}

function DeviceSizeContext({ children }: Props) {
  const { setDeviceSize } = useDeviceSize()

  useEffect(() => {
    const handleResize = debounce(() => {
      const { innerWidth } = window
      let nextDeviceSize: DeviceSize

      if (innerWidth >= MOBILE_WIDTH_BREAKPOINT) {
        nextDeviceSize = DeviceSize.PC
      } else if (innerWidth < MOBILE_WIDTH_BREAKPOINT) {
        nextDeviceSize = DeviceSize.MOBILE
      }

      setDeviceSize(nextDeviceSize)
    }, 300)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setDeviceSize])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export { DeviceSizeContext }
export default useDeviceSize
