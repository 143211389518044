import {
  collection,
  getFirestore,
  type CollectionReference,
  type DocumentData,
  type PartialWithFieldValue,
  type QueryConstraint,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'

import { removeNestedUndefinedOrEmpty } from '@/utils/object'
import { sendTelegramMessage } from '@/utils/telegram'
import { getFirebaseApp } from '@/utils/webpush'

const app = getFirebaseApp()
const db = getFirestore(app)

export function getCollection(collectionName: string) {
  return collection(db, collectionName)
}

export const readCollectionDocs =
  <T>({
    collectionRef,
    queryConstraints = [],
  }: {
    collectionRef: CollectionReference<DocumentData>
    queryConstraints?: QueryConstraint[]
  }) =>
  async ({
    withNotification = false,
  }: {
    withNotification?: boolean
  }): Promise<T[]> => {
    try {
      const { getDocs, query } = await import('firebase/firestore')
      const querySnapshot = await getDocs(
        query(collectionRef, ...queryConstraints),
      )

      const docs = querySnapshot.docs.map((_doc) => ({
        id: _doc.id,
        ..._doc.data(),
      })) as T[]

      if (withNotification) {
        await sendTelegramMessage({
          topic: `readCollectionDocs / ${collectionRef.path}`,
          message: ``,
        })
      }

      return docs
    } catch (e) {
      return []
    }
  }

export const readCollectionDocById =
  <T>({
    collectionRef,
  }: {
    collectionRef: CollectionReference<DocumentData>
  }) =>
  async ({
    id,
    withNotification = false,
  }: {
    id: string
    withNotification?: boolean
  }): Promise<T | null> => {
    try {
      const { doc, getDoc } = await import('firebase/firestore')
      const docRef = doc(collectionRef, id)
      const docSnap = await getDoc(docRef)

      if (docSnap.exists()) {
        const _doc = { id: docSnap.id, ...docSnap.data() } as T

        if (withNotification) {
          await sendTelegramMessage({
            topic: `readDocById / ${collectionRef.path}`,
            message: ``,
          })
        }

        return _doc
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }

export const createCollectionDoc =
  <T>({
    collectionRef,
  }: {
    collectionRef: CollectionReference<DocumentData>
  }) =>
  async ({
    doc: _doc,
    withNotification = false,
  }: {
    doc: Omit<T, 'id'>
    withNotification?: boolean
  }): Promise<string> => {
    try {
      const { addDoc } = await import('firebase/firestore')
      const { id } = await addDoc(collectionRef, _doc)

      if (withNotification) {
        await sendTelegramMessage({
          topic: `createCollectionDoc / ${collectionRef.path}`,
          message: `생성 정보(JSON)\n${JSON.stringify(_doc, null, 4)}`,
        })
      }

      return id
    } catch (e) {
      return ''
    }
  }

export const updateCollectionDoc =
  <T>({
    collectionRef,
  }: {
    collectionRef: CollectionReference<DocumentData>
  }) =>
  async ({
    id,
    doc: _doc,
    withNotification = false,
  }: {
    id: string
    doc: Omit<T, 'id'>
    withNotification?: boolean
  }): Promise<string> => {
    try {
      const { doc, updateDoc } = await import('firebase/firestore')
      const docRef = doc(collectionRef, id)
      await updateDoc(docRef, removeNestedUndefinedOrEmpty(_doc))

      if (withNotification) {
        await sendTelegramMessage({
          topic: `updateDoc / ${collectionRef.path}`,
          message: ``,
        })
      }

      return id
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

export const deleteCollectionDoc =
  ({ collectionRef }: { collectionRef: CollectionReference<DocumentData> }) =>
  async ({
    id,
    withNotification = false,
  }: {
    id: string
    withNotification?: boolean
  }): Promise<string> => {
    try {
      const { doc, deleteDoc } = await import('firebase/firestore')
      const docRef = doc(collectionRef, id)
      await deleteDoc(docRef)

      if (withNotification) {
        await sendTelegramMessage({
          topic: `deleteDoc / ${collectionRef.path}`,
          message: `id: ${id}}`,
        })
      }

      return id
    } catch (e) {
      return ''
    }
  }

export function arrayObjectToTwoDimensionalArray<T>(
  arrayObject: Record<number, T>,
): T[] {
  const array = []
  arrayObject &&
    Object.keys(arrayObject).forEach((key) => {
      if (key === 'createdAt' || key === 'updatedAt') {
        return
      }

      const item = arrayObject[key]

      array.push(item)
    })

  return array
}

export const converter = <T extends { id: string }>() => ({
  toFirestore: (data: PartialWithFieldValue<T>) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) =>
    ({ id: snap.id, ...snap.data() } as T),
})
