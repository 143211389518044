import React, { ReactNode, useEffect, useState } from 'react'

import { HEADER_HEIGHT } from '@/components/layout/Header'
import { STICKY_HEADER_Z_INDEX } from '@/constants/zIndex'
import { useIsWebview } from '@/utils/hooks/useIsWebview'

interface Props {
  children: ReactNode
  breakpoint: number
}

function StickyHeader({ children, breakpoint }: Props) {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isWebview] = useIsWebview()

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window
      const scrollThreshold = breakpoint // 스크롤 이하를 의미하는 임의의 값

      if (scrollY > scrollThreshold) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [breakpoint])

  return (
    <div
      className="sticky flex w-full items-center gap-4 bg-white px-4 transition-all duration-500"
      style={{
        height: HEADER_HEIGHT,
        top: isWebview
          ? isScrolled
            ? 0
            : -1 * HEADER_HEIGHT
          : isScrolled
          ? HEADER_HEIGHT
          : 0,
        marginTop: -1 * HEADER_HEIGHT,
        zIndex: STICKY_HEADER_Z_INDEX,
        boxShadow: isScrolled ? '0 2px 4px rgba(0, 0, 0, 0.1)' : undefined,
      }}>
      {children}
    </div>
  )
}

export default StickyHeader
