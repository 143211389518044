/* eslint-disable @typescript-eslint/no-misused-promises */
import { useRouter } from 'next/router'

import { ReactNode, useState, createContext, useMemo, useEffect } from 'react'

import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Drawer, Menu, notification } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useUser } from 'next-firebase-auth'

import ProfileItem from '@/components/ckk/items/ProfileItem'
import Logo from '@/components/ckk/Logo'
import Img from '@/components/common/Img'
import { NOTIFICATION_TYPE_MAP } from '@/constants/ckk/notification'
import { DRAWER_Z_INDEX, HEADER_Z_INDEX } from '@/constants/zIndex'
import { Game, GAME_MAP } from '@/types/game'
import { UserNotification } from '@/types/notification'
import { MenuConfig, MenuTag } from '@/types/shared'
import { renderTag } from '@/utils/common'
import { 오늘이면_시간_분을_아니면_월_일을_반환 } from '@/utils/dayjs'
import {
  readUserNotifications,
  updateUserNotification,
} from '@/utils/firebase/notification'
import { sendGAEvent } from '@/utils/gtag'

const Context = createContext({ name: 'Default' })

interface Props {
  menuConfig: MenuConfig
  mainLogo: ReactNode
  children?: ReactNode
}

const HEADER_HEIGHT = 48

const MyMenu = ({ title, tags }: { title: string; tags: MenuTag[] }) => {
  return (
    <div className="flex items-center justify-between">
      {title}
      <div className="flex gap-1">
        {tags?.map((tag) => (
          <span key={tag}>{renderTag(tag)}</span>
        ))}
      </div>
    </div>
  )
}

const getInitialOpenKeys = (pathname: string) => {
  const temp = pathname.split('/')
  return temp[1] && temp[2] ? [`/${temp[1]}/${temp[2]}`] : []
}

function Header({ menuConfig, mainLogo, children }: Props) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const { pathname, push } = useRouter()
  const [openKeys, setOpenKeys] = useState<string[]>(
    getInitialOpenKeys(pathname),
  )
  const [notifications, setNotifications] = useState<UserNotification[]>([])
  const [api, contextHolder] = notification.useNotification({
    stack: {
      threshold: 3,
    },
    placement: 'bottomRight',
  })
  const user = useUser()
  const isUserLoading = user.clientInitialized === false
  const userUid = user?.firebaseUser?.uid

  useEffect(() => {
    if (!userUid) return

    const fetch = () => {
      return readUserNotifications({
        uid: userUid,
        withNotification: false,
        isRead: false,
      })
    }
    const fetchAndSet = async () => {
      const nextNotifications = await fetch()
      setNotifications(nextNotifications)
    }

    fetchAndSet()
  }, [userUid])

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen)

  const handleNotificationClick = async () => {
    if (!userUid) {
      return
    }

    api.destroy()

    const nextNotifications =
      notifications ??
      (await readUserNotifications({
        uid: user.firebaseUser.uid,
        withNotification: false,
        isRead: false,
      }))

    if (nextNotifications.length === 0) {
      push('/account/notification')
    }

    nextNotifications.forEach((_notification) => {
      const { notificationType, target, content, url, createdAt } =
        _notification
      api.open({
        style: { padding: '1rem' },
        message: null,
        description: (
          <div className="flex gap-2">
            <Img
              className="h-12 w-12 rounded-full"
              src={
                GAME_MAP[url.includes('ckt') ? Game.CKT : Game.CKK].image
                  .appIcon
              }
            />
            <div className="flex flex-col justify-start gap-1">
              <span className="text-xs">
                {NOTIFICATION_TYPE_MAP[notificationType].title}
              </span>
              <div className="mr-7 font-bold leading-5">{content}</div>
              <div className="flex items-center gap-2 !text-xs">
                <span>{target}</span>
                <span>·</span>
                <span>{오늘이면_시간_분을_아니면_월_일을_반환(createdAt)}</span>
              </div>
            </div>
          </div>
        ),
        duration: null,
        onClick: () => {
          updateUserNotification({
            doc: {
              ..._notification,
              isRead: true,
            },
            id: _notification.id,
          })
          setNotifications((prevNotifications) =>
            prevNotifications.filter(
              (prevNotification) => prevNotification.id !== _notification.id,
            ),
          )
          push(url)
          api.destroy()
        },
      })
    })
  }

  const handleMenuClick = ({ key }) => {
    sendGAEvent({
      category,
      action: `메뉴 클릭`,
      label: key,
    })

    push({ pathname: key })
  }

  const handleMenuOpenChange = (nextOpenKeys: string[]) => {
    const nextOpenKey = nextOpenKeys.find((key) => openKeys.indexOf(key) === -1)
    setOpenKeys(nextOpenKey ? [nextOpenKey] : [])
    sendGAEvent({
      category,
      action: `서브 메뉴 ${nextOpenKey ? '열기' : '닫기'}`,
      label: nextOpenKey,
    })
  }

  const items: ItemType[] = menuConfig.map((menu) => {
    if (menu.hasSubMenu) {
      const {
        pathname: parentMenuPathname,
        icon,
        title: parentMenuTitle,
        tags: parentTags,
        childrenMenu,
      } = menu
      return {
        key: parentMenuPathname,
        icon,
        label: <MyMenu tags={parentTags} title={parentMenuTitle} />,
        children: childrenMenu.map(
          ({
            pathname: childrenMenuPathname,
            title: childrenMenuTitle,
            tags,
          }) => ({
            key: parentMenuPathname + childrenMenuPathname,
            label: <MyMenu tags={tags} title={childrenMenuTitle} />,
          }),
        ),
      }
    }

    const { pathname: singleMenuPathname, icon, title, tags } = menu
    return {
      key: singleMenuPathname,
      icon,
      label: <MyMenu tags={tags} title={title} />,
    }
  })

  const category = isSidebarOpen ? '메뉴' : '헤더'
  const drawerHeight: number =
    49 + menuConfig.length * 40 + (menuConfig.length + 1) * 4 + 11 + 48

  return (
    <Context.Provider value={useMemo(() => ({ name: 'Ant Design' }), [])}>
      {contextHolder}
      <header
        className="sticky top-0 flex w-full flex-col items-center justify-between"
        id="header"
        style={{
          zIndex: HEADER_Z_INDEX,
          borderBottom: '1px solid rgb(240, 240, 240)',
          transition: 'border-bottom 0.3s ease-in-out',
        }}>
        <Drawer
          closable={false}
          contentWrapperStyle={{
            maxWidth: '62rem',
            width: '100%',
            margin: '0 auto',
          }}
          getContainer="#__next"
          height={drawerHeight}
          open={isSidebarOpen}
          placement="top"
          styles={{
            header: {
              padding: 0,
            },
            content: {
              borderRadius: '0 0 1rem 1rem',
            },
            body: {
              padding: 0,
            },
          }}
          title={<div style={{ height: 49 }} />}
          zIndex={DRAWER_Z_INDEX}
          onClose={toggleSidebar}>
          <Menu
            defaultSelectedKeys={[pathname]}
            items={items}
            mode="inline"
            openKeys={openKeys}
            onClick={handleMenuClick}
            onOpenChange={handleMenuOpenChange}
          />
          <div className="mx-4 mb-4 mt-2 flex items-center justify-start gap-4">
            <Logo game={Game.CKK} />
            <Logo game={Game.CKT} />
          </div>
        </Drawer>
        <div
          className="flex w-full items-center justify-between bg-white px-4"
          style={{
            height: HEADER_HEIGHT,
            position: isSidebarOpen ? 'sticky' : 'relative',
            zIndex: isSidebarOpen ? 4 : undefined,
          }}>
          <div>{mainLogo}</div>
          <div className="flex items-center gap-4">
            {(isUserLoading || userUid) && (
              <ProfileItem
                displayName={user?.firebaseUser?.displayName}
                loading={isUserLoading}
                photoURL={user?.firebaseUser?.photoURL}
                size="xs"
                uid={userUid}
                withDisplayName={false}
              />
            )}
            {(isUserLoading || userUid) && (
              <Badge
                className={`cursor-pointer ${
                  isUserLoading ? 'animate-pulse' : ''
                }`}
                count={userUid ? notifications?.length : 0}
                overflowCount={99}
                size="small">
                <FontAwesomeIcon
                  className="text-xl leading-4"
                  icon={faBell}
                  onClick={userUid && handleNotificationClick}
                />
              </Badge>
            )}
            <div
              className={isSidebarOpen ? 'open' : ''}
              id="nav-icon"
              onClick={toggleSidebar}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        {children}
      </header>
    </Context.Provider>
  )
}

export { HEADER_HEIGHT }
export default Header
