import { NextRouter } from 'next/router'

import React, { ErrorInfo } from 'react'

// import { sendTelegramMessage } from '@/utils/telegram'

import Error from 'pages/_error'

interface Props {
  router: NextRouter
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.router.pathname !== this.props.router.pathname) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasError: false,
      })
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { componentStack } = errorInfo
    // eslint-disable-next-line no-console
    console.log(componentStack)
    // sendTelegramMessage({
    //   topic: `클라이언트 에러 - ${this.props.router.asPath}`,
    //   message: [
    //     '[Stack]',
    //     error.stack,
    //     `[ComponentStack]`,
    //     componentStack,
    //   ].join('\n'),
    // })
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return (
        <Error
          message="브라우저에서 문제가 생겼습니다. 제작자에게 신고해주세요!"
          statusCode={500}
        />
      )
    }

    return children
  }
}
