import { ReactNode } from 'react'

import { LocalizedString } from '@/types/ckk'
import { FirestoreCommon } from '@/types/firebase'
import { AdminRole } from '@/utils/admin'

export type MenuConfig = (SingleMenu | ParentMenu)[]

export enum MenuTag {
  NEW = 'NEW',
  HOT = 'HOT',
  UPDATE = 'UPDATE',
}

export interface Menu {
  title: string
  pathname: string
  tags?: MenuTag[]
  level?: AdminRole
}

export interface SingleMenu extends Menu {
  icon: ReactNode
  hasSubMenu: false
  /** 파생되는 pathname 배열
   * * 해당 pathname에 가게되면 해당 메뉴가 활성화됩니다.
   */
  linkedPathnames?: string[]
}

export interface ParentMenu extends Menu {
  hasSubMenu: true
  icon: ReactNode
  childrenMenu: ChildMenu[]
}

export type ChildMenu = Menu & {
  /** 파생되는 pathname 배열
   * * 해당 pathname에 가게되면 해당 메뉴가 활성화됩니다.
   */
  linkedPathnames?: string[]
}

export type Url = string

export type Size = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs'

export interface MinimizeCurrency {
  id: string
  name: LocalizedString
  image: string
}

export interface BaseReward {
  currency: {
    id: string
  }
  amount: number
}

export interface Reward {
  currency: MinimizeCurrency
  amount: number
}

export type BaseCouponCommon = Omit<CouponCommon, 'rewards'> & {
  rewards: BaseReward[]
}

export interface CouponCommon extends FirestoreCommon {
  code: string
  startDate: string
  /** @example 2023/01/21 12/03 */
  expirationDate: string
  description: LocalizedString
  rewards: Reward[]
}
