import {
  설탕노움_비밀창고_사이트_대표_이미지,
  설탕노움_비밀창고_로고_이미지,
} from '@/constants/ckk/image'
import { LocalizedString } from '@/types/ckk'
import { GAME_MAP, Game } from '@/types/game'

export const SITE_URL = 'https://sk2gacha.com/'
export const CKK_APPLICATION_NAME = '쿠키런 킹덤 : 설탕노움의 비밀창고'
export const CKK_DESCRIPTION =
  '쿠키런 킹덤 꿀팁을 원한다면? 설탕노움의 비밀창고!'
export const CKO_APPLICATION_NAME = '쿠키런 오븐브레이크 : 설탕노움의 비밀창고'
export const CKO_DESCRIPTION = '쿠키런 오븐브레이크 꿀팁 여기 다 모여있다!'
export const AUTHOR = 'Sugar Gnome'

const COMMON_KEYWORDS = [
  'cookie',
  'coupon',
  'redeem',
  'cookie run',
  'devsisters',
  '쿠키런',
]

export const FAVICON_URL = 설탕노움_비밀창고_로고_이미지

/**
 * 트위터 카드 형식
 * * https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started
 */
export enum TwitterCard {
  SUMMARY = 'summary',
  SUMMARY_LARGE_IMAGE = 'summary_large_image',
  APP = 'app',
  PLAYER = 'player',
}

export enum OgType {
  WEBSITE = 'website',
}

export interface Config {
  url: string
  pathname: string
  title: LocalizedString
  author: string
  applicationName: LocalizedString
  description: LocalizedString
  keywords: string[]
  image: {
    appIcon: string
    main: {
      url: string
      alt: string
      width: number
      height: number
    }
  }
  favicon: {
    url: string
    alt: string
  }
  twitter: {
    /** @username for the website used in the card footer.	 */
    site: string
    /** @username for the content creator / author.	 */
    creator: string
    card: TwitterCard
  }
  og: {
    type: OgType
  }
}

export const SITE_CONFIG = {
  title: {
    ko: '설탕노움의 비밀창고',
    en: "Sugar Gnome's Warehouse",
  } as LocalizedString,
  image: {
    appIcon: 설탕노움_비밀창고_로고_이미지,
  },
  pathname: '/',
}

export const GAME_DOMAIN_CONFIG: Record<Game, Config> = {
  [Game.CKK]: {
    url: 'https://sk2gacha.com/ckk',
    pathname: '/ckk',
    author: AUTHOR,
    title: {
      ko: [GAME_MAP[Game.CKK].name.ko, SITE_CONFIG.title.ko].join(' : '),
      en: [GAME_MAP[Game.CKK].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    applicationName: {
      ko: [GAME_MAP[Game.CKK].name.ko, SITE_CONFIG.title.ko].join(' : '),
      en: [GAME_MAP[Game.CKK].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    description: {
      ko: '쿠키런 킹덤 꿀팁을 원한다면? 설탕노움의 비밀창고!',
      en: 'Looking for Cookie Run Kingdom tips? Sugar Gnome has got you covered!',
    },
    keywords: [...COMMON_KEYWORDS, 'cookie run kingdom', '쿠키런 킹덤'],
    image: {
      appIcon: 설탕노움_비밀창고_로고_이미지,
      main: {
        url: 설탕노움_비밀창고_사이트_대표_이미지,
        alt: [GAME_MAP[Game.CKK].name.en, SITE_CONFIG.title.en].join(' : '),
        width: 1024,
        height: 500,
      },
    },
    favicon: {
      url: FAVICON_URL,
      alt: [GAME_MAP[Game.CKK].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    twitter: {
      site: '@L4W04PJiwvhQ3TZ',
      creator: '@L4W04PJiwvhQ3TZ',
      card: TwitterCard.SUMMARY_LARGE_IMAGE,
    },
    og: {
      type: OgType.WEBSITE,
    },
  },
  [Game.CKO]: {
    url: 'https://sk2gacha.com/cko',
    pathname: '/cko',
    author: AUTHOR,
    title: {
      ko: [GAME_MAP[Game.CKO].name.ko, SITE_CONFIG.title.ko].join(' : '),
      en: [GAME_MAP[Game.CKO].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    applicationName: {
      ko: [GAME_MAP[Game.CKO].name.ko, SITE_CONFIG.title.ko].join(' : '),
      en: [GAME_MAP[Game.CKO].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    description: {
      ko: '쿠키런 오븐브레이크 꿀팁 여기 다 모여있다!',
      en: 'All the best Cookie Run OvenBreak tips are here!',
    },
    keywords: [
      ...COMMON_KEYWORDS,
      'cookie run ovenbreak',
      '쿠키런 오븐브레이크',
    ],
    image: {
      appIcon: GAME_MAP[Game.CKO].image.appIcon,
      main: {
        url: 설탕노움_비밀창고_사이트_대표_이미지,
        alt: [GAME_MAP[Game.CKO].name.en, SITE_CONFIG.title.en].join(' : '),
        width: 1024,
        height: 500,
      },
    },
    favicon: {
      url: FAVICON_URL,
      alt: [GAME_MAP[Game.CKO].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    twitter: {
      site: '@L4W04PJiwvhQ3TZ',
      creator: '@L4W04PJiwvhQ3TZ',
      card: TwitterCard.SUMMARY_LARGE_IMAGE,
    },
    og: {
      type: OgType.WEBSITE,
    },
  },
  [Game.BRIXITY]: {
    url: 'https://sk2gacha.com/brixity',
    pathname: '/brixity',
    author: AUTHOR,
    title: {
      ko: [GAME_MAP[Game.BRIXITY].name.ko, SITE_CONFIG.title.ko].join(' : '),
      en: [GAME_MAP[Game.BRIXITY].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    applicationName: {
      ko: [GAME_MAP[Game.BRIXITY].name.ko, SITE_CONFIG.title.ko].join(' : '),
      en: [GAME_MAP[Game.BRIXITY].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    description: {
      ko: '브릭시티 꿀팁을 원한다면? 설탕노움의 비밀창고!',
      en: 'Looking for Brixity tips? Sugar Gnome has got you covered!',
    },
    keywords: [...COMMON_KEYWORDS, 'brixity', '브릭시티'],
    image: {
      appIcon: GAME_MAP[Game.BRIXITY].image.appIcon,
      main: {
        url: 설탕노움_비밀창고_사이트_대표_이미지,
        alt: [GAME_MAP[Game.BRIXITY].name.en, SITE_CONFIG.title.en].join(' : '),
        width: 1024,
        height: 500,
      },
    },
    favicon: {
      url: FAVICON_URL,
      alt: [GAME_MAP[Game.BRIXITY].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    twitter: {
      site: '@L4W04PJiwvhQ3TZ',
      creator: '@L4W04PJiwvhQ3TZ',
      card: TwitterCard.SUMMARY_LARGE_IMAGE,
    },
    og: {
      type: OgType.WEBSITE,
    },
  },
  [Game.CKT]: {
    url: 'https://sk2gacha.com/ckt',
    pathname: '/ckt',
    author: AUTHOR,
    title: {
      ko: [GAME_MAP[Game.CKT].name.ko, SITE_CONFIG.title.ko].join(' : '),
      en: [GAME_MAP[Game.CKT].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    applicationName: {
      ko: [GAME_MAP[Game.CKT].name.ko, SITE_CONFIG.title.ko].join(' : '),
      en: [GAME_MAP[Game.CKT].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    description: {
      ko: '쿠키런 모험의 탑 꿀팁을 원한다면? 설탕노움의 비밀창고!',
      en: 'Looking for Cookie Run Puzzle World tips? Sugar Gnome has got you covered!',
    },
    keywords: [
      ...COMMON_KEYWORDS,
      'cookie run tower of adventures',
      '쿠키런 모험의 탑',
    ],
    image: {
      appIcon: GAME_MAP[Game.CKT].image.appIcon,
      main: {
        url: 'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/4a0230f4-0596-4f2d-678e-644e22c99600/public',
        alt: [GAME_MAP[Game.CKT].name.en, SITE_CONFIG.title.en].join(' : '),
        width: 1024,
        height: 500,
      },
    },
    favicon: {
      url: FAVICON_URL,
      alt: [GAME_MAP[Game.CKT].name.en, SITE_CONFIG.title.en].join(' : '),
    },
    twitter: {
      site: '@L4W04PJiwvhQ3TZ',
      creator: '@L4W04PJiwvhQ3TZ',
      card: TwitterCard.SUMMARY_LARGE_IMAGE,
    },
    og: {
      type: OgType.WEBSITE,
    },
  },
}

export const LOCAL_STORAGE_KEY_MAP = {
  ckk: {
    cookie: 'CKK_COOKIE',
    crystal: 'CKK_CRYSTAL',
    cookieStone: 'CKK_COOKIE_STONE',
    treasure: 'CKK_TREASURE',
    devPlayAccount: 'DEV_PLAY_ACCOUNT',
    cookieGachaHistory: 'CKK_COOKIE_GACHA_HISTORY',
    treasureGachaHistory: 'CKK_TREASURE_GACHA_HISTORY',
    gachaOption: 'CKK_GACHA_OPTION',
    cookieGachaGauge: 'CKK_COOKIE_GACHA_GAUGE',
    id: 'ID',
    webview: 'WEBVIEW',
    viewedArenaPostIds: 'VIEWED_ARENA_POST_IDS',
    viewedArticleIds: 'VIEWED_ARTICLE_IDS',
  },
  ckt: {
    crystal: 'CKT_CRYSTAL',
    cookieStone: 'CKT_COOKIE_STONE',
    cookieGachaHistory: 'CKT_COOKIE_GACHA_HISTORY',
    gachaOption: 'CKT_GACHA_OPTION',
    viewedPartyIds: 'CKT_VIEWED_PARTY_IDS',
  },
}
