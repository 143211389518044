export enum NotificationType {
  /** 킹덤 자유게시판 글 새로운 댓글 알림 */
  ARTICLE_NEW_COMMENT = 'ARTICLE_NEW_COMMENT',
  /** 킹덤 덱 게시판 글 새로운 댓글 알림 */
  DECK_NEW_COMMENT = 'DECK_NEW_COMMENT',
  /** 모험의 탑 자유게시판 글 새로운 댓글 알림 */
  CKT_ARTICLE_NEW_COMMENT = 'CKT_ARTICLE_NEW_COMMENT',
  /** 부모 댓글의 새로운 댓글 알림 */
  NEW_COMMENT_REPLY = 'NEW_COMMENT_REPLY',
}

export interface UserNotification {
  id: string
  notificationType: NotificationType
  /** 알림을 유발한 콘텐츠(글 제목, 댓글 내용) */
  target: string
  /** 알림이 생긴 원인에 대한 콘텐츠(댓글 내용) */
  content: string
  image?: string
  url: string
  uid: string
  createdAt: string
  updatedAt: string
  isRead: boolean
}
