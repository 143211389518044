/* eslint-disable @typescript-eslint/naming-convention */
import Link from 'next/link'
import { useRouter } from 'next/router'

import { ReactNode } from 'react'

import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar } from 'antd'
import { AvatarSize } from 'antd/lib/avatar/AvatarContext'

import { Size } from '@/types/shared'

interface Props {
  loading?: boolean
  uid: string
  photoURL: string
  displayName: string
  size?: Size
  withDisplayName?: boolean
  children?: ReactNode
  withLink?: boolean
  className?: string
}

function ProfileItem({
  uid,
  photoURL,
  displayName,
  size = 'md',
  withDisplayName = true,
  withLink = true,
  children,
  className,
  loading = false,
}: Props) {
  const { locale } = useRouter()
  const getAvatarSize = (): AvatarSize => {
    switch (size) {
      case 'xs':
        return 'small'
      case 'sm':
        return 'small'
      case 'md':
        return 'large'
      case 'lg':
        return 'large'
      case 'xl':
        return 'large'
      default:
        return 'small'
    }
  }

  displayName =
    displayName ?? (locale === 'ko' ? '익명의 쿠키' : 'Anonymous Cookie')

  const renderInner = () => {
    return (
      <>
        {loading ? (
          <Avatar className="animate-pulse" size={getAvatarSize()} />
        ) : photoURL ? (
          <Avatar size={getAvatarSize()} src={photoURL} />
        ) : (
          <Avatar
            icon={<FontAwesomeIcon icon={faUser} />}
            size={getAvatarSize()}
          />
        )}
        {withDisplayName && (
          <div
            className="ml-1 overflow-hidden text-ellipsis whitespace-nowrap break-all"
            style={{ maxWidth: '7.8rem' }}>
            {displayName}
          </div>
        )}
        {children}
      </>
    )
  }

  return withLink ? (
    <Link
      className={`flex items-center text-black ${className}`}
      href={`/account/profile/detail/${uid}`}>
      {renderInner()}
    </Link>
  ) : (
    <div className={`flex items-center text-black ${className}`}>
      {renderInner()}
    </div>
  )
}

export default ProfileItem
