import { useRouter } from 'next/router'

import { useEffect } from 'react'

import { StatusCodes } from 'http-status-codes'
import { i18n } from 'next-i18next'

import Status from '@/components/ckk/Status'
import Layout from '@/components/layout/Layout'
import en from '@/public/locales/en/common.json'
import ko from '@/public/locales/ko/common.json'

interface Props {
  statusCode: number
  message?: string
}

function Error({ statusCode, message }: Props) {
  const { locale } = useRouter()

  useEffect(() => {
    i18n.addResourceBundle(locale, 'common', locale === 'ko' ? ko : en)
  }, [locale])

  return (
    <Layout
      title={
        locale === 'ko' ? '설탕노움의 비밀창고' : "Sugar Gnome's Warehouse"
      }
      visibleAd={{
        anchor: false,
        middle: false,
      }}
      withLayoutDescription={false}
      withLayoutTitle={false}>
      <Status
        message={
          message ?? statusCode
            ? locale === 'ko'
              ? '서버에 문제가 생겼습니다. 제작자에게 신고해주세요!'
              : 'There is a problem with the server. Please report it to the developer!'
            : locale === 'ko'
            ? '브라우저에서 문제가 생겼습니다. 제작자에게 신고해주세요!'
            : 'There was a problem with your browser. Please report it to the developer!'
        }
        statusCode={StatusCodes.INTERNAL_SERVER_ERROR}
      />
    </Layout>
  )
}

Error.getInitialProps = ({ response, error }) => {
  const result = response
    ? { statusCode: response.statusCode, message: response.message }
    : error
    ? { statusCode: error.statusCode, message: response.message }
    : { statusCode: 404 }
  return result
}

export default Error
