const TOKEN = '7380660302:AAG4WyqN2N0e7G9Hs-sDB8OzON8t4S3r8d8'
/** 유저 유니크 채팅 아이디 */
const CHAT_ID = '5392023870'

// telegram send message response interface
interface TelegramResponse {
  ok: boolean
  result: {
    message_id: number
    from: {
      id: number
      is_bot: boolean
      first_name: string
      username: string
    }
  }
}

export const sendTelegramMessage = async ({
  topic,
  from,
  message,
}: {
  topic: string
  from?: string
  message: string
}) => {
  try {
    /** 개발환경에서는 보내지 않음 */
    if (process.env.NODE_ENV === 'development') return

    const { default: axios } = await import('axios')
    // axios post action with telegram interface
    const {
      data: { result },
    } = await axios.post<TelegramResponse>(
      `https://api.telegram.org/bot${TOKEN}/sendMessage`,
      {
        chat_id: CHAT_ID,
        text: `[${topic}] ${from ? `${from}님의 ` : ''}
${message}`,
      },
    )
    return result
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
