import Head from 'next/head'

import {
  Fragment,
  ReactEventHandler,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react'

type ImageElementProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

interface Props extends ImageElementProps {
  preload?: boolean
  isNeedEagerLoading?: boolean
  onLoad?: ReactEventHandler<HTMLImageElement>
}

function Img({
  src,
  alt,
  preload = false,
  children: fallback = null,
  isNeedEagerLoading = false,
  onLoad,
  className,
  style,
  ...restProps
}: Props) {
  const [, setLoading] = useState(true)
  const [isValidImage, setIsValidImage] = useState(!!src)

  useEffect(() => {
    setIsValidImage(!!src)
  }, [src])

  const enableImage = () => {
    setIsValidImage(true)
    setLoading(false)
  }

  const disableImage = () => {
    setIsValidImage(false)
    setLoading(false)
  }

  const handleError = (error: SyntheticEvent<HTMLImageElement, Event>) => {
    // eslint-disable-next-line no-console
    console.error(error)
    disableImage()
  }

  const handLoad: ReactEventHandler<HTMLImageElement> = (image) => {
    onLoad?.(image)
    enableImage()
  }

  const imageFailed = !isValidImage

  return (
    <Fragment key={src}>
      {preload && (
        <Head>
          <link as="image" href={src} rel="prefetch" />
        </Head>
      )}
      {imageFailed ? (
        fallback
      ) : src?.endsWith('webm') ? (
        <video autoPlay loop muted className={className} style={style}>
          <source src={src} type="video/webm" />
        </video>
      ) : (
        <img
          key={src}
          alt={alt}
          className={className}
          loading={isNeedEagerLoading ? 'eager' : 'lazy'}
          src={src}
          style={style}
          title={alt}
          onError={handleError}
          onLoad={handLoad}
          {...restProps}
        />
      )}
    </Fragment>
  )
}

export default Img
