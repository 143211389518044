import { useRouter } from 'next/router'

import { useRef, useEffect } from 'react'

function AnchorBottomAd() {
  const router = useRouter()
  const adsLoaded = useRef(false)

  useEffect(() => {
    const loadAd = () => {
      if (typeof window !== 'undefined' && window.adsbygoogle) {
        try {
          window.adsbygoogle = window.adsbygoogle || []
          window.adsbygoogle.push({})
          adsLoaded.current = true
        } catch (error) {
          // sendTelegramMessage({
          //   topic: `클라이언트 에러 - AnchorBottomAd`,
          //   message: JSON.stringify(error, null, 4),
          // })
        }
      }
    }

    if (router.query && !adsLoaded.current) {
      setTimeout(loadAd, 0)
    }
  }, [router.query])

  return (
    <ins
      className="adsbygoogle anchor-bottom adsense-horizontal"
      data-ad-client={process.env.NEXT_PUBLIC_ADSENSE_CLIENT}
      data-ad-slot="2735160150"
      data-full-width-responsive="true"
      style={{ display: 'block' }}
    />
  )
}

export default AnchorBottomAd
