import { initializeApp } from 'firebase/app'
import { init } from 'next-firebase-auth'

import { firebaseConfig } from '@/utils/webpush'

const initAuth = () => {
  initializeApp(firebaseConfig)
  init({
    authPageURL: '/account/login',
    appPageURL: '/',
    /** required */
    loginAPIEndpoint: '/api/login',
    /** required */
    logoutAPIEndpoint: '/api/logout',
    // onLoginRequestError: (error) => {
    //   sendTelegramMessage({
    //     topic: 'Auth Error',
    //     message: `Error onLoginRequestError\nError Name: ${error.name}\nError Message: ${error.message}\nError Stack: ${error.stack}\nError Cause: ${error.cause}`,
    //   })
    // },
    // onLogoutRequestError: (error) => {
    //   sendTelegramMessage({
    //     topic: 'Auth Error',
    //     message: `Error onLogoutRequestError\nError Name: ${error.name}\nError Message: ${error.message}\nError Stack: ${error.stack}\nError Cause: ${error.cause}`,
    //   })
    // },
    // useFirebaseAdminDefaultCredential: true,
    firebaseAdminInitConfig: {
      credential: {
        projectId: 'sugar-gnome-warehouse',
        clientEmail:
          'firebase-adminsdk-v3k4f@sugar-gnome-warehouse.iam.gserviceaccount.com',
        // The private key must not be accessible on the client side.
        privateKey: process.env.FIREBASE_PRIVATE_KEY
          ? process.env.FIREBASE_PRIVATE_KEY.replace(/\\n/g, '\n')
          : undefined,
      },
      databaseURL: 'https://sugar-gnome-warehouse-default-rtdb.firebaseio.com',
    },
    firebaseClientInitConfig: firebaseConfig,
    cookies: {
      name: 'SugarGnomeWarehouse', // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [
        process.env.COOKIE_SECRET_CURRENT,
        process.env.COOKIE_SECRET_PREVIOUS,
      ],
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: '/',
      sameSite: 'strict',
      secure: true, // set this to false in local (non-HTTPS) development
      signed: true,
    },
    // onVerifyTokenError: (err) => {
    //   sendTelegramMessage({
    //     topic: 'Error',
    //     message: `Error onVerifyTokenError: ${err}`,
    //   })
    // },
    // onTokenRefreshError: (err) => {
    //   sendTelegramMessage({
    //     topic: 'Error',
    //     message: `Error onTokenRefreshError: ${err}`,
    //   })
    // },
  })
}

export default initAuth
