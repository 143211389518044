import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { useEffect } from 'react'

import { updateDepth } from '@egjs/persist'
import { ConfigProvider, Modal } from 'antd'
import enUS from 'antd/locale/en_US'
import koKR from 'antd/locale/ko_KR'
import dayjs from 'dayjs'
import { getMessaging, isSupported, onMessage } from 'firebase/messaging'
import { createBrowserHistory } from 'history'
import { SSRConfig, appWithTranslation, useTranslation } from 'next-i18next'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'styled-components'

import ErrorBoundary from '@/components/ErrorBoundary'
import nextI18nConfig from '@/next-i18next.config'
import GlobalStyle from '@/styles/global-styles'
import { themes } from '@/styles/theme'
import { ThemeType } from '@/types/theme'
import { isBrowser, isServer } from '@/utils/env'
import { getIsWebview } from '@/utils/flutter'
import { GA4_TRACKING_ID, useGtag } from '@/utils/gtag'
import { useIsWebview } from '@/utils/hooks/useIsWebview'
import initAuth from '@/utils/initAuth'
import { getFirebaseApp } from '@/utils/webpush'
import { DeviceSizeContext } from '@/zustand/deviceSize'
import { GameContext } from '@/zustand/game'
import useLayout from '@/zustand/layout'

import '@egjs/react-flicking/dist/flicking.css'
import 'public/fonts/style.css'
import 'configs/recoil'
import 'styles/globals.css'

// FIXME: styled-components가 react 18버전을 대응하면 제거
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ThemeProviderProxy: any = ThemeProvider
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GlobalStyleProxy: any = GlobalStyle

initAuth()

type Props = AppProps<SSRConfig>

// eslint-disable-next-line @typescript-eslint/naming-convention
const InsideRecoilApp = ({ Component, pageProps }: Props) => {
  const { locale } = useRouter()

  useEffect(() => {
    if (locale === 'ko') {
      require(`dayjs/locale/ko`)
      dayjs.locale(locale)
    } else if (locale === 'en') {
      require(`dayjs/locale/en`)
      dayjs.locale(locale)
    }
  }, [locale])

  useEffect(() => {
    if (isServer) return

    const customHistory = createBrowserHistory()
    customHistory.listen(() => {
      updateDepth()
    })
  }, [])

  return (
    <ConfigProvider
      componentSize="small"
      locale={locale === 'ko' ? koKR : enUS}>
      <Component {...pageProps} />
    </ConfigProvider>
  )
}

function MyApp(appProps: Props) {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [isWebview, setIsWebview] = useIsWebview()
  const { setHeaderHeight } = useLayout()

  const { pathname, isReady, query, asPath } = router

  if (
    !appProps?.pageProps?._nextI18Next &&
    !pathname.includes('/admin') &&
    !pathname.includes('/_error')
  ) {
    throw new Error(`${pathname}의 pageProps에 _nextI18Next가 없습니다.`)
  }

  useGtag()

  useEffect(() => {
    if (isServer) return

    const init = async () => {
      const firebaseApp = getFirebaseApp()
      const isSupportBrowser = await isSupported()

      if (isSupportBrowser) {
        const messaging = getMessaging(firebaseApp)
        onMessage(messaging, (message) => {
          const { notification, data } = message
          const { body, title } = notification

          Modal.info({
            title,
            content: body,
            okText: t('ok'),
            onOk: () => {
              if (data.route) {
                router.push(data.route)
              }
            },
            getContainer: '#__next',
          })
        })
      }
    }

    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isReady || isBrowser === false) return

    const _isWebview = getIsWebview(window.navigator.userAgent)

    setIsWebview(_isWebview)
  }, [isReady, query.webview, setIsWebview])

  useEffect(() => {
    const isDetailPage =
      asPath.includes('/detail') &&
      !asPath.includes('/ckt/artifact') &&
      !asPath.includes('/ckk/treasure')
    const _headerHeight = isDetailPage ? 48 * 2 : 48 - (isWebview ? 1 : 0) * 48

    setHeaderHeight(_headerHeight)
  }, [asPath, isWebview, setHeaderHeight])

  const body = (
    <ThemeProviderProxy theme={themes[ThemeType.LIGHT]}>
      <ErrorBoundary router={router}>
        <RecoilRoot>
          <GameContext>
            <DeviceSizeContext>
              <InsideRecoilApp {...appProps} />
            </DeviceSizeContext>
          </GameContext>
        </RecoilRoot>
      </ErrorBoundary>
    </ThemeProviderProxy>
  )

  return (
    <>
      <Head>
        <title>{t('ckk.title')}</title>
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          name="viewport"
        />
      </Head>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4_TRACKING_ID}`}
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', '${GA4_TRACKING_ID}');
        `}
      </Script>
      <GlobalStyleProxy {...themes[ThemeType.LIGHT]} />
      {body}
    </>
  )
}

export default appWithTranslation(MyApp, nextI18nConfig)
