import { create } from 'zustand'

import { HEADER_HEIGHT } from '@/components/layout/Header'

const useLayout = create<{
  headerHeight: number
  innerStickyHeaderFromTop: number
  setHeaderHeight: (headerHeight: number) => void
}>((set) => ({
  headerHeight: HEADER_HEIGHT,
  innerStickyHeaderFromTop: HEADER_HEIGHT + 8,
  setHeaderHeight: (headerHeight) =>
    set({
      headerHeight,
      innerStickyHeaderFromTop: headerHeight + 8,
    }),
}))

export default useLayout
