import { User } from 'firebase/auth'

const ADMIN_EMAILS = ['leegun2003@gmail.com']
const MEMBER_EMAILS = ['leegun2010@naver.com', 'jae976229@gmail.com']

export enum AdminRole {
  ADMIN = 2,
  MEMBER = 1,
  USER = 0,
}

export const getIsAdmin = (firebaseUser: User) =>
  firebaseUser?.emailVerified && ADMIN_EMAILS.includes(firebaseUser?.email)

export const getAdminRole = (firebaseUser: User): AdminRole => {
  if (!firebaseUser?.emailVerified) return AdminRole.USER

  if (ADMIN_EMAILS.includes(firebaseUser?.email)) {
    return AdminRole.ADMIN
  }

  if (MEMBER_EMAILS.includes(firebaseUser?.email)) {
    return AdminRole.MEMBER
  }

  return AdminRole.USER
}
