export function removeEmpty(obj) {
  for (const key in obj) {
    if (
      obj[key] === undefined ||
      obj[key] === null ||
      obj[key] === '' ||
      (typeof obj[key] === 'object' &&
        !Array.isArray(obj[key]) &&
        obj[key] !== undefined &&
        obj[key] !== null &&
        !Object.keys(obj[key]).length)
    ) {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      removeEmpty(obj[key])
    }
  }
  return obj
}

export function removeNestedUndefinedOrEmpty<T>(obj: T): T {
  return {
    ...removeEmpty({
      ...removeEmpty({
        ...removeEmpty({ ...removeEmpty({ ...removeEmpty({ ...obj }) }) }),
      }),
    }),
  }
}
