import {
  브릭시티_로고_이미지,
  쿠키런_모험의_탑_로고_이미지,
  쿠키런_오븐브레이크_로고_이미지,
  쿠키런_킹덤_로고_이미지,
} from '@/constants/image'
import { LocalizedString } from '@/types/ckk'

export enum Game {
  /** 쿠키런 킹덤 */
  CKK = 'CKK',
  /** 쿠키런 오븐브레이크 */
  CKO = 'CKO',
  /** 브릭시티 */
  BRIXITY = 'BRIXITY',
  /** 쿠키런 모험의 탑 */
  CKT = 'CKT',
}

export const GAME_MAP: Record<
  Game,
  {
    name: LocalizedString
    image: {
      appIcon: string
    }
  }
> = {
  [Game.CKK]: {
    name: {
      ko: '쿠키런: 킹덤',
      en: 'Cookie Run: Kingdom',
    },
    image: { appIcon: 쿠키런_킹덤_로고_이미지 },
  },
  [Game.CKO]: {
    name: {
      ko: '쿠키런: 오븐브레이크',
      en: 'Cookie Run: OvenBreak',
    },
    image: { appIcon: 쿠키런_오븐브레이크_로고_이미지 },
  },
  [Game.BRIXITY]: {
    name: {
      ko: '브릭시티',
      en: 'Brixity',
    },
    image: { appIcon: 브릭시티_로고_이미지 },
  },
  [Game.CKT]: {
    name: {
      ko: '쿠키런: 모험의 탑',
      en: 'Cookie Run: Tower of Adventures',
    },
    image: { appIcon: 쿠키런_모험의_탑_로고_이미지 },
  },
}
