import { useRouter } from 'next/router'

import { useEffect } from 'react'

import { isServer } from '@/utils/env'

export const GA4_TRACKING_ID = process.env.NEXT_PUBLIC_GA4_TRACKING_ID

// route가 변경될 때 gtag에서
export const useGtag = () => {
  const router = useRouter()

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' || isServer) return

    const handleRouteChange = (url: URL) => {
      window.gtag('config', GA4_TRACKING_ID, {
        page_path: url,
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events])
}

interface GTagEvent {
  action: string
  category?: string
  label?: string
  value?: string
}

export const sendGAEvent = ({ action, category, label, value }: GTagEvent) => {
  if (window.gtag === undefined) return

  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  })
}

export const setGAUserId = ({ userId }: { userId: string }) => {
  if (window.gtag === undefined) return

  window.gtag('config', GA4_TRACKING_ID, {
    user_id: userId,
  })
}
