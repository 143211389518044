import {
  faUser,
  faGem,
  faCoins,
  faIceCream,
  faUsers,
  faGamepad,
  faFontAwesome,
  faCompass,
  faTicket,
  faCookie,
  faBaby,
  faCalendar,
  faHistory,
  faGifts,
  faMessage,
  faMicrochip,
  faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type User } from 'firebase/auth'
import { TFunction } from 'i18next'

import { COMMON_MENUS } from '@/constants/menu'
import { MenuConfig, MenuTag } from '@/types/shared'
import { AdminRole } from '@/utils/admin'

export const COOKIE_RUN_KINGDOM_MENUS = ({
  user,
  t,
}: {
  user: User
  t: TFunction
}): MenuConfig => [
  {
    title: t('cookie'),
    icon: <FontAwesomeIcon className="mr-2" icon={faUser} />,
    pathname: '/ckk/cookie',
    hasSubMenu: true,
    tags: [MenuTag.HOT],
    childrenMenu: [
      {
        title: t('cookie_gacha.title'),
        pathname: '/gacha',
        tags: [],
      },
      { title: t('cookie_tier.title'), pathname: '/tier', tags: [MenuTag.HOT] },
      {
        title: t('pages.cookie.tier_maker.title'),
        pathname: '/tier/maker',
        tags: [MenuTag.NEW],
      },
      {
        title: t('cookie_dictionary.title'),
        pathname: '/dictionary',
        tags: [],
      },
      {
        title: t('cookie_cooldown.title'),
        pathname: '/cooldown',
      },
    ],
  },
  {
    title: t('treasure'),
    icon: <FontAwesomeIcon className="mr-2" icon={faGem} />,
    pathname: '/ckk/treasure',
    hasSubMenu: true,
    childrenMenu: [
      { title: t('treasure_gacha.title'), pathname: '/gacha' },
      { title: t('treasure_tier.title'), pathname: '/tier' },
      { title: t('treasure_dictionary.title'), pathname: '/dictionary' },
    ],
  },
  {
    title: t('currency'),
    icon: <FontAwesomeIcon className="mr-2" icon={faCoins} />,
    pathname: '/ckk/currency',
    hasSubMenu: true,
    childrenMenu: [
      {
        title: t('pages.currency.dictionary.title'),
        pathname: '/dictionary',
      },
    ],
    tags: [MenuTag.NEW],
  },
  {
    title: t('topping'),
    icon: <FontAwesomeIcon className="mr-2" icon={faIceCream} />,
    pathname: '/ckk/topping',
    hasSubMenu: true,
    childrenMenu: [
      {
        title: t('pages.topping.dictionary.title'),
        pathname: '/dictionary',
      },
      // { title: t('pages.topping.simulator.title'), pathname: '/simulator' },
    ],
    tags: [MenuTag.NEW],
  },
  {
    title: t('words.community'),
    icon: <FontAwesomeIcon className="mr-2" icon={faUsers} />,
    pathname: '/ckk/community',
    hasSubMenu: true,
    tags: [MenuTag.HOT],
    childrenMenu: [
      {
        title: t('pages.free_board.title'),
        pathname: '/free_board',
        tags: [MenuTag.NEW],
      },
      {
        title: t('pages.community_deck.title'),
        pathname: '/deck',
        tags: [MenuTag.NEW],
      },
    ],
  },
  {
    title: t('gameplay.title'),
    icon: <FontAwesomeIcon className="mr-2" icon={faGamepad} />,
    pathname: '/ckk/gameplay',
    hasSubMenu: true,
    tags: [MenuTag.HOT],
    childrenMenu: [
      {
        title: t('pages.kingdom_arena.title'),
        pathname: '/kingdom_arena',
        tags: [MenuTag.HOT, MenuTag.UPDATE],
      },
      {
        title: t('guild_battle.title'),
        pathname: '/guild_battle',
        tags: [MenuTag.UPDATE],
      },
      {
        title: t('world_exploration.title'),
        pathname: '/world_exploration',
        tags: [MenuTag.UPDATE],
      },
      {
        title: t('pages.cookie_alliance.title'),
        pathname: '/cookie_alliance',
        tags: [MenuTag.HOT, MenuTag.UPDATE],
      },
      {
        title: t('super_mayhem.title'),
        pathname: '/super_mayhem',
        tags: [],
      },
    ],
  },
  {
    title: t('kingdom.title'),
    icon: <FontAwesomeIcon className="mr-2" icon={faFontAwesome} />,
    pathname: '/ckk/kingdom',
    hasSubMenu: true,
    childrenMenu: [
      {
        title: t('kingdom.laboratory.title'),
        pathname: '/laboratory',
        tags: [MenuTag.UPDATE],
      },
      {
        title: t('pages.landmark.title'),
        pathname: '/landmark',
        tags: [MenuTag.NEW],
      },
      { title: t('kingdom.stock.title'), pathname: '/stock' },
      { title: t('kingdom.tips.title'), pathname: '/tips' },
    ],
  },
  {
    title: t('guide'),
    icon: <FontAwesomeIcon className="mr-2" icon={faCompass} />,
    pathname: '/ckk/guide',
    hasSubMenu: true,
    childrenMenu: [
      {
        title: t('pages.soulstone_farming.title'),
        pathname: '/soulstone_farming',
        tags: [MenuTag.NEW],
      },
    ],
  },
  // {
  //   title: t('pages.holiday_express.title'),
  //   icon: <FontAwesomeIcon className="mr-2" icon={faTrain} />,
  //   pathname: '/ckk/holiday_express',
  //   hasSubMenu: false,
  //   tags: [MenuTag.NEW],
  // },
  {
    title: t('pages.coupon_auto_register.title'),
    icon: <FontAwesomeIcon className="mr-2" icon={faTicket} />,
    pathname: '/ckk/coupon',
    hasSubMenu: false,
    tags: [MenuTag.HOT],
  },
  {
    title: t('pages.fun.title'),
    icon: <FontAwesomeIcon className="mr-2" icon={faCookie} />,
    pathname: '/ckk/fun',
    hasSubMenu: true,
    tags: [],
    childrenMenu: [
      {
        title: t('pages.mbti.title'),
        pathname: '/mbti',
        tags: [],
      },
      {
        title: t('pages.guess_cookie.title'),
        pathname: '/guess_cookie',
        tags: [],
      },
    ],
  },
  // {
  //   title: t('package_efficiency.title'),
  //   icon: <FontAwesomeIcon icon={faGifts} className="mr-2" />,
  //   pathname: '/ckk/package',
  //   hasSubMenu: false,
  // },
  {
    title: t('pages.beginner_guide.title'),
    icon: <FontAwesomeIcon className="mr-2" icon={faBaby} />,
    pathname: '/ckk/beginner_guide',
    hasSubMenu: false,
    tags: [MenuTag.NEW],
  },
  {
    title: t('updates.title'),
    icon: <FontAwesomeIcon className="mr-2" icon={faCalendar} />,
    pathname: '/ckk/update',
    hasSubMenu: false,
  },
  // {
  //   title: t('subscribe.title'),
  //   icon: <FontAwesomeIcon icon={faBell} className="mr-2" />,
  //   pathname: '/ckk/subscribe',
  //   hasSubMenu: false,
  // },
  {
    title: t('pages.previous_pages.title'),
    icon: <FontAwesomeIcon className="mr-2" icon={faHistory} />,
    pathname: '/ckk',
    hasSubMenu: true,
    childrenMenu: [
      { title: t('pages.romance_kingdom.title'), pathname: '/romance_kingdom' },
      {
        title: t('pages.error_busters.title'),
        pathname: '/error_busters',
      },
    ],
  },
  ...COMMON_MENUS({ user, t }),
]

export const COOKIE_RUN_KINGDOM_ADMIN_MENUS: MenuConfig = [
  {
    icon: <FontAwesomeIcon className="mr-2" icon={faCookie} />,
    title: '쿠키',
    pathname: '/ckk/admin/cookie',
    linkedPathnames: ['/create', '/detail/[id]'],
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    icon: <FontAwesomeIcon className="mr-2" icon={faGem} />,
    title: '보물',
    pathname: '/ckk/admin/treasure',
    linkedPathnames: ['/create', '/detail/[id]'],
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    title: '모든 덱',
    pathname: '/ckk/admin/deck',
    linkedPathnames: ['/create', '/detail/[id]'],
    icon: <FontAwesomeIcon className="mr-2" icon={faPeopleGroup} />,
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    title: '쿠폰',
    pathname: '/ckk/admin/coupon',
    linkedPathnames: ['/create', '/detail/[id]'],
    icon: <FontAwesomeIcon className="mr-2" icon={faTicket} />,
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    title: '패키지',
    pathname: '/ckk/admin/package',
    linkedPathnames: ['/create', '/detail/[id]'],
    icon: <FontAwesomeIcon className="mr-2" icon={faGifts} />,
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    title: '재화',
    pathname: '/ckk/admin/currency',
    linkedPathnames: ['/create', '/detail/[id]'],
    icon: <FontAwesomeIcon className="mr-2" icon={faCoins} />,
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    title: '연구',
    pathname: '/ckk/admin/technology',
    linkedPathnames: ['/create', '/detail/[id]'],
    icon: <FontAwesomeIcon className="mr-2" icon={faMicrochip} />,
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    title: '유저',
    pathname: '/ckk/admin/user',
    linkedPathnames: ['/create', '/detail/[id]'],
    icon: <FontAwesomeIcon className="mr-2" icon={faUser} />,
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    title: '피드백',
    pathname: '/ckk/admin/opinion',
    linkedPathnames: ['/create', '/detail/[id]'],
    icon: <FontAwesomeIcon className="mr-2" icon={faMessage} />,
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
]
