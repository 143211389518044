import Link from 'next/link'
import { useRouter } from 'next/router'

import { CSSProperties, ReactNode } from 'react'

import Img from '@/components/common/Img'
import { GAME_DOMAIN_CONFIG, SITE_CONFIG } from '@/constants/config'
import { GAME_MAP, Game } from '@/types/game'
import { sendGAEvent } from '@/utils/gtag'

interface Props {
  style?: CSSProperties
  game?: Game | null
  children?: ReactNode
}

function Logo({ game, children, style }: Props) {
  const { locale } = useRouter()

  const handleLogoClick = () => {
    sendGAEvent({
      category: '헤더',
      action: `로고 클릭`,
    })
  }

  const name = game ? GAME_MAP[game].name : SITE_CONFIG.title
  const {
    image: { appIcon },
    pathname,
    title,
  } = game ? GAME_DOMAIN_CONFIG[game] : SITE_CONFIG
  const localeName = name[locale]
  const localeTitle = title[locale]
  const loading = game === undefined

  return (
    <Link
      className="ckk flex items-center justify-center break-keep text-center text-base !text-black"
      href={pathname}
      title={localeTitle}
      onClick={handleLogoClick}>
      {children ??
        (loading ? (
          <>
            <div className="h-8 w-8 animate-pulse rounded bg-gray-300" />
            <span className="ml-2 hidden h-6 w-40 animate-pulse rounded-lg bg-gray-200 sm:inline dark:bg-gray-300" />
          </>
        ) : (
          <>
            <Img alt={localeName} className="h-8 w-8 rounded" src={appIcon} />
            <span className="ml-2 hidden sm:inline" style={style}>
              {localeTitle}
            </span>
          </>
        ))}
    </Link>
  )
}

export default Logo
