/** og:image에 쓰이는 대표 이미지 */
export const 설탕노움_비밀창고_사이트_대표_이미지 =
  'https://blog.kakaocdn.net/dn/4vJ04/btrBYfOfogI/thMdU5cKloD0OC8C2TMoB0/img.png'

/** 정사각형 대표 이미지 */
export const 설탕노움_비밀창고_대형_로고_이미지 =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/73d8c58d-9b36-4942-9e7c-1fd77d175500/public'

export const 설탕노움_비밀창고_로고_이미지 =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/3928b445-4802-4858-6a5e-9dcbdeb6c100/public'

export const 쿨타임용_시계_이미지 =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/0c7e0a83-8a6e-4c78-3d9b-ecff02690900/public'
export const 시작_쿨타임용_시계_이미지 =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/aab1fad9-5a7e-44cf-c5c3-fc4654fda300/public'

export const 카카오톡_로고_이미지 =
  'https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/ccbd82af-1d3f-4648-7674-d187b8e6e200/public'
