import type { QueryConstraint } from 'firebase/firestore'

import { UserNotification } from '@/types/notification'
import {
  getCollection,
  createCollectionDoc,
  updateCollectionDoc,
  deleteCollectionDoc,
  readCollectionDocById,
  readCollectionDocs,
} from '@/utils/firebase'
import { sendTelegramMessage } from '@/utils/telegram'

const userNotificationCollectionRef = getCollection('userNotifications')

export const createUserNotification = createCollectionDoc<UserNotification>({
  collectionRef: userNotificationCollectionRef,
})

export const updateUserNotification = updateCollectionDoc<UserNotification>({
  collectionRef: userNotificationCollectionRef,
})

export const deleteUserNotification = deleteCollectionDoc({
  collectionRef: userNotificationCollectionRef,
})

export const readUserNotificationById = readCollectionDocById<UserNotification>(
  {
    collectionRef: userNotificationCollectionRef,
  },
)

export const readUserNotifications = async ({
  uid,
  withNotification,
  isRead,
}: {
  uid: string
  withNotification: boolean
  isRead?: boolean
}) => {
  const { where } = await import('firebase/firestore')

  const queryConstraints: QueryConstraint[] = []

  if (isRead !== undefined) {
    queryConstraints.push(where('isRead', '==', isRead))
  }
  queryConstraints.push(where('uid', '==', uid))

  return await readCollectionDocs<UserNotification>({
    collectionRef: userNotificationCollectionRef,
    queryConstraints,
  })({ withNotification })
}

export const readUserNotificationByEnName = async ({
  enName,
  withUserNotification = false,
}: {
  enName: string
  withUserNotification?: boolean
}) => {
  const { query, where, limit, getDocs } = await import('firebase/firestore')
  const userNotifications: UserNotification[] = []
  const queryConstraints: QueryConstraint[] = []

  queryConstraints.push(where('name.en', '==', enName))

  const q = query(userNotificationCollectionRef, ...queryConstraints, limit(1))
  const querySnapshot = await getDocs(q)

  querySnapshot.forEach((_doc) => {
    const _userNotification = {
      id: _doc.id,
      ..._doc.data(),
    } as UserNotification
    userNotifications.push(_userNotification)
  })

  if (withUserNotification) {
    await sendTelegramMessage({
      topic: '단일 알림 조회 / readTreasureByEnName',
      message: `조회 Query : ${JSON.stringify({ enName })}
조회 결과(json)
${JSON.stringify(userNotifications, null, 4)}`,
    })
  }

  const userNotification = userNotifications?.length
    ? userNotifications[0]
    : null

  return userNotification
}
