import {
  faCoins,
  faCookie,
  faDragon,
  faLandmark,
  faMedal,
  faTicket,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type User } from 'firebase/auth'
import { TFunction } from 'i18next'

import Img from '@/components/common/Img'
import { COMMON_MENUS } from '@/constants/menu'
import { MenuConfig, MenuTag } from '@/types/shared'
import { AdminRole } from '@/utils/admin'

export const COOKIE_RUN_TOWER_OF_ADVENTURE_MENUS = ({
  user,
  t,
}: {
  user: User
  t: TFunction
}): MenuConfig => [
  {
    title: t('cookie'),
    icon: (
      <Img
        src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/690a4638-7be7-42de-fbce-134c109da100/public"
        style={{
          width: 18,
        }}
      />
    ),
    pathname: '/ckt/cookie',
    hasSubMenu: true,
    tags: [MenuTag.HOT],
    childrenMenu: [
      {
        title: t('pages.ckt.cookie.tier.title'),
        pathname: '/tier',
        tags: [MenuTag.HOT, MenuTag.UPDATE],
      },
      {
        title: t('pages.ckt.cookie.dictionary.title'),
        pathname: '/dictionary',
        tags: [MenuTag.UPDATE],
      },
      {
        title: t('pages.ckt.cookie.gacha.title'),
        pathname: '/gacha',
        tags: [MenuTag.NEW],
      },
    ],
  },
  {
    title: t('words.artifact'),
    icon: (
      <Img
        src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/1b932bba-252c-40b4-e146-47197a80de00/public"
        style={{
          width: 18,
        }}
      />
    ),
    pathname: '/ckt/artifact',
    hasSubMenu: true,
    tags: [MenuTag.NEW],
    childrenMenu: [
      // {
      //   title: t('pages.ckt.artifact.tier.title'),
      //   pathname: '/tier',
      //   tags: [MenuTag.HOT, MenuTag.UPDATE],
      // },
      {
        title: t('pages.ckt.artifact.dictionary.title'),
        pathname: '/dictionary',
        tags: [MenuTag.UPDATE],
      },
      // {
      //   title: t('pages.ckt.artifact.gacha.title'),
      //   pathname: '/gacha',
      //   tags: [MenuTag.NEW],
      // },
    ],
  },
  {
    title: t('words.community'),
    icon: (
      <Img
        src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/7684a78e-65a9-4ef3-5b78-fc8fbdab8900/public"
        style={{
          width: 18,
        }}
      />
    ),
    pathname: '/ckt/community',
    hasSubMenu: true,
    tags: [MenuTag.HOT],
    childrenMenu: [
      {
        title: t('pages.ckt.community.free_board.title'),
        pathname: '/free_board',
        tags: [MenuTag.NEW],
      },
    ],
  },
  {
    title: t('raid'),
    icon: (
      <Img
        src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/aec256c4-25e3-4bda-a78c-fd3b60b47600/public"
        style={{
          width: 18,
        }}
      />
    ),
    pathname: '/ckt/raid',
    hasSubMenu: true,
    tags: [MenuTag.HOT],
    childrenMenu: [
      {
        title: t('pages.ckt.raid.dictionary.title'),
        pathname: '/dictionary',
        tags: [MenuTag.NEW],
      },
    ],
  },
  {
    title: t('pages.ckt.coupon.title'),
    icon: (
      <Img
        src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/e4ae5cd5-d29a-413c-e6d3-424750f7b000/public"
        style={{
          width: 18,
        }}
      />
    ),
    pathname: '/ckt/coupon',
    hasSubMenu: false,
    tags: [MenuTag.HOT],
  },
  {
    title: t('stage'),
    icon: (
      <Img
        src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/f02371f8-6028-4e80-587d-39f4f3198000/public"
        style={{
          width: 18,
        }}
      />
    ),
    pathname: '/ckt/stage',
    hasSubMenu: true,
    tags: [MenuTag.HOT, MenuTag.NEW],
    childrenMenu: [
      {
        title: t('pages.ckt.stage.dictionary.title'),
        pathname: '/dictionary',
        tags: [MenuTag.NEW],
      },
    ],
  },
  // {
  //   title: t('community'),
  //   icon: (
  //     <Img
  //       src="https://imagedelivery.net/57rIj2o4cJ62boUSs_DLpA/7684a78e-65a9-4ef3-5b78-fc8fbdab8900/public"
  //       style={{
  //         width: 18,
  //       }}
  //     />
  //   ),
  //   pathname: '/ckt/community',
  //   hasSubMenu: true,
  //   tags: [MenuTag.NEW],
  //   childrenMenu: [
  //     {
  //       title: t('pages.ckt.community.party.title'),
  //       pathname: '/party',
  //       tags: [MenuTag.NEW],
  //     },
  //   ],
  // },
  {
    title: t('pages.ckt.achievement.title'),
    icon: <FontAwesomeIcon className="mr-2" icon={faMedal} />,
    pathname: '/ckt/achievement',
    hasSubMenu: false,
    tags: [MenuTag.HOT, MenuTag.NEW],
  },
  ...COMMON_MENUS({ user, t }),
]

export const COOKIE_RUN_TOWER_OF_ADVENTURE_ADMIN_MENUS: MenuConfig = [
  {
    icon: <FontAwesomeIcon className="mr-2" icon={faCookie} />,
    title: '쿠키',
    pathname: '/ckt/admin/cookie',
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    icon: <FontAwesomeIcon className="mr-2" icon={faCookie} />,
    title: '쿠키 티어',
    pathname: '/ckt/admin/cookie/tier',
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    icon: <FontAwesomeIcon className="mr-2" icon={faCookie} />,
    title: '아티팩트',
    pathname: '/ckt/admin/artifact',
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    icon: <FontAwesomeIcon className="mr-2" icon={faLandmark} />,
    title: '스테이지',
    pathname: '/ckt/admin/stage',
    hasSubMenu: false,
    level: AdminRole.MEMBER,
  },
  {
    icon: <FontAwesomeIcon className="mr-2" icon={faDragon} />,
    title: '레이드',
    pathname: '/ckt/admin/raid',
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    icon: <FontAwesomeIcon className="mr-2" icon={faTicket} />,
    title: '쿠폰',
    pathname: '/ckt/admin/coupon',
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
  {
    icon: <FontAwesomeIcon className="mr-2" icon={faCoins} />,
    title: '재화',
    pathname: '/ckt/admin/currency',
    hasSubMenu: false,
    level: AdminRole.ADMIN,
  },
]
